import React, { useState, useRef, useEffect } from "react";
import "../assets/css/contact.css";
import "../assets/css/homeAfter.css";
import { BsFillTelephoneFill } from "react-icons/bs";
import { SlEnvolopeLetter } from "react-icons/sl";
import { ImLocation2 } from "react-icons/im";
import Fade from "react-reveal/Fade";
import { MdFactory } from "react-icons/md";
import Footer from "../wrappers/Footer";
import Loader from "../wrappers/Loader";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Header from "../wrappers/Header";
import CryptoJS from "crypto-js";
import { v4 as uuidv4 } from "uuid";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const HRComponent = () => {
  const languageData = useSelector((state) => state.data);
  const [showMenu, setShowMenu] = useState(false);
  const [isSuccessArray, setIsSuccessArray] = useState([false, false, false]);
  const language = useSelector((state) => state.language);
  const url = `https://www.focusactuators.com/human_resources/${language}`;

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const { language: x } = useParams();
  useEffect(() => {
    if (x && !["en", "fr", "ru", "tr"].includes(x)) {
      window.location.pathname = `/404`;
    } else if (x && window.location.pathname.endsWith("/")) {
      const newPathname = window.location.pathname.slice(0, -1);
      window.location.pathname = newPathname;
    }
  }, [x]);

  const [userInfo, setUserInfo] = useState("");
  const [tel, setTel] = useState("");
  const [subject, setSubject] = useState("HUMAN RESOURCES FORM");
  const [from, setFrom] = useState("");
  const [message, setMessage] = useState("");
  const [files, setFiles] = useState("");

  const handleSubjectInputChange = (e) => {
    setSubject(e.target.value);
  };
  const handleNameInputChange = (e) => {
    setUserInfo(e.target.value);
  };
  const handleEmailInputChange = (e) => {
    setFrom(e.target.value);
  };

  const handlePhoneInputChange = (e) => {
    setTel(e.target.value);
  };

  const handleMessageInputChange = (e) => {
    setMessage(e.target.value);
  };

  const handleFileInputChange = (e) => {
    const selectedFile = e.target.files[0];
    setFiles(selectedFile);
  };

  const form = useRef();
  const [isSuccess, setIsSuccess] = useState(false);
  const handleClickButton = async (e, tabIndex) => {
    e.preventDefault();

    const encryptedText = CryptoJS.AES.encrypt(
      uuidv4(),
      process.env.REACT_APP_SSK
    ).toString();

    const FD = new FormData(form.current);
    FD.append("accessToken", encryptedText);
    FD.append("ownerName", "mailto:kutluhan@focusactuators.com");
    FD.append("to", "mailto:kutluhan@focusactuators.com");
    FD.append("host", "smtp.gmail.com");
    FD.append("service", "gmail");

    try {
      const res = await axios.post("https://nodemailer.enesatalay.com/", FD, {
        auth: {
          username: "OSICrew_Node_Mailler",
          password: "1786oSi?173",
        },
      });
      if (res) {
        const updatedSuccessArray = [...isSuccessArray];
        updatedSuccessArray[tabIndex] = true;
        setIsSuccessArray(updatedSuccessArray);
        toast.success("Mesajınız gönderildi.", { autoClose: 4500 });
        console.log(res);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.message, { autoClose: 4500 });
    }
  };

  const getPDFLink = (language) => {
    switch (language) {
      case "en":
        return "/pdf_en/focus_brochure.pdf";
      case "fr":
        return "/pdf_fr/focus_brochure.pdf";
      case "ru":
        return "/pdf_ru/focus_brochure.pdf";
      case "tr":
        return "/pdf_tr/focus_brochure.pdf";
      default:
        return "/pdf_en/focus_brochure.pdf";
    }
  };

  const pdfLink = getPDFLink(language);

  return (
    <>
      <Helmet>
        <title> {languageData.human_resources_helmet}</title>
        <meta
          name="description"
          content="To apply for a position, kindly complete the form below and join our team!"
        />
        <link rel="canonical" href={url} />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <ToastContainer />
      <Loader />
      <Fade top delay={800}>
        <div className="downing-page-div">
          <Header showMenu={showMenu} toggleMenu={toggleMenu} />
          <div className="container text-header d-flex justify-content-center flex-column align-items-center contact-page">
            {!showMenu && (
              <>
                <div className="col-10 col-lg-9  title-sticky">
                  <h1 className="title-main">
                    {languageData.human_resources_1}
                  </h1>
                </div>
                <div className="bg-main col-12 d-flex align-items-center justify-content-center flex-column">
                  {" "}
                  <div className="col-10 mb-5 d-flex ">
                    <div className="light-bg-gray text-white w-100 p-3">
                      <div className="bg-gray m-0 p-2 text-white">
                        <h2>{languageData.apply_job}</h2>
                        <p>{languageData.hr_text}</p>
                      </div>

                      <div className=" bg-gray mt-4 p-2">
                        <form
                          className="contactForm"
                          ref={form}
                          onSubmit={handleClickButton}
                          encType="multipart/form-data"
                        >
                          <div className="">
                            <div className="col-12 mb-2">
                              <div className="form-group">
                                <input
                                  id="subject"
                                  name="subject"
                                  type="text"
                                  required
                                  aria-required="true"
                                  value={subject}
                                  onChange={handleSubjectInputChange}
                                  className="career_button_inner d-none"
                                />
                                <div className="help-block with-errors" />
                              </div>
                            </div>
                            <div className="col-12 mb-2">
                              <div className="form-group">
                                <input
                                  type="text"
                                  name="userInfo"
                                  className="form-control"
                                  placeholder={languageData.role}
                                  required
                                  data-error="Please enter your Name"
                                  onChange={handleNameInputChange}
                                />

                                <div className="help-block with-errors" />
                              </div>
                            </div>
                            <div className="col-12 mb-2">
                              <div className="form-group">
                                <input
                                  type="text"
                                  name="from"
                                  className="form-control"
                                  placeholder={languageData.email}
                                  required
                                  onChange={handleEmailInputChange}
                                  data-error="Please enter your Email"
                                />

                                <div className="help-block with-errors" />
                              </div>
                            </div>
                            <div className="col-12 mb-2">
                              <div className="form-group">
                                <input
                                  type="text"
                                  name="tel"
                                  className="form-control"
                                  placeholder={languageData.phone}
                                  required
                                  onChange={handlePhoneInputChange}
                                  data-error="Please enter your Phone Number"
                                />

                                <div className="help-block with-errors" />
                              </div>
                            </div>

                            <div className="col-12">
                              <div className="form-group">
                                <textarea
                                  type="text"
                                  name="message"
                                  id="message"
                                  className="form-control"
                                  rows={4}
                                  onChange={handleMessageInputChange}
                                  placeholder={languageData.cover_letter}
                                  required
                                  data-error="Please enter your Message"
                                />
                                <div className="help-block with-errors" />
                              </div>
                            </div>
                            <div className="col-12 mt-2">
                              <div className="form-group">
                                <input
                                  type="file"
                                  name="file"
                                  files={files}
                                  onChange={handleFileInputChange}
                                />

                                <div className="help-block with-errors" />
                              </div>
                            </div>
                            <div className="col-12 d-flex justify-content-center">
                              <p
                                className="button-focus mt-5"
                                type="submit"
                                style={{ cursor: "pointer" }}
                                onSubmit={handleClickButton}
                                disabled={isSuccess}
                              >
                                {languageData.send}
                              </p>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="black-background-contact mt-5">
                    <div className="container d-flex justify-content-center align-items-center flex-column">
                      <div className="col-5 col-lg-9 col-sm-10  mt-5 d-lg-flex  d-md-flex justify-content-center text-white ">
                        <div className="col-12 col-lg-5 col-md-5   d-flex justify-content-center align-items-center flex-column p-3 bg-gray mb-2">
                          <BsFillTelephoneFill />
                          <p className="text-white"></p>
                        </div>

                        <div className="col-12 col-lg-5 offset-lg-1 col-md-5 offset-md-1 d-flex justify-content-center align-items-center flex-column bg-gray mb-2">
                          <SlEnvolopeLetter />
                          <>
                            <p className="text-white">sales@denz.co</p>
                          </>
                        </div>
                      </div>

                      <div className="col-lg-9 col-md-10 col-5 col-sm-10 mt-5 d-flex text-white bg-gray justify-content-center flex-column">
                        <div className="d-lg-flex text-white ">
                          <div className="col-lg-6 col-12 p-3">
                            <div className="text-lg-center">
                              <ImLocation2 />
                            </div>

                            <h6>
                              <b> {languageData.headquarter}</b>
                            </h6>
                            <p>
                              Mutlukent Mahallesi 2016/1 Sokak No: 32 Çankaya
                              06800 Ankara Türkiye
                            </p>
                            <div className="text-lg-center">
                              <MdFactory />
                            </div>

                            <h6>
                              <b> {languageData.factory}</b>
                            </h6>
                            <p>
                              Ivedik OSB 1354. Cadde 1357. Sokak No:38
                              Yenimahalle/Ankara/Türkiye
                            </p>
                          </div>

                          <div className="col-lg-6 col-12">
                            <iframe
                              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d720.825027777664!2d32.71436530106184!3d39.88539397296358!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d3388309a352c1%3A0x96afe6b90c595552!2sMutlukent%2C%202016%2F1.%20Sk.%20No%3A32%2C%2006800%20%C3%87ankaya%2FAnkara!5e1!3m2!1str!2str!4v1739256899030!5m2!1str!2str"
                              style={{ border: 0 }}
                              allowFullScreen
                              loading="lazy"
                              referrerPolicy="no-referrer-when-downgrade"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-9 col-md-10 col-5 col-sm-10 mb-5 mt-5">
                        <div className="col-12 d-flex">
                          <div className="bg-gray text-white w-100">
                            <div className="light-bg-gray d-flex align-items-center p-2 w-100">
                              <div className="col-6 d-flex">
                                <h3 className="p-0 m-0">
                                  {languageData.focus_brochure}
                                </h3>
                              </div>
                              <div className="col-6 d-flex justify-content-end">
                                <a
                                  target="_blank"
                                  href={pdfLink}
                                  aria-label="brochure"
                                >
                                  <p className="button-focus m-0">
                                    {languageData.download}
                                  </p>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="white-background-contact "></div>
                </div>
              </>
            )}
          </div>
          {!showMenu && <Footer />}
        </div>
      </Fade>
    </>
  );
};

export default HRComponent;
