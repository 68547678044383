import React, { useState, useRef, useEffect } from "react";
import "../assets/css/contact.css";
import "../assets/css/homeAfter.css";
import { BsFillTelephoneFill } from "react-icons/bs";
import { SlEnvolopeLetter } from "react-icons/sl";
import { ImLocation2 } from "react-icons/im";
import Fade from "react-reveal/Fade";
import Footer from "../wrappers/Footer";
import Loader from "../wrappers/Loader";
import Header from "../wrappers/Header";
import { MdFactory } from "react-icons/md";
import { Helmet } from "react-helmet";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import CryptoJS from "crypto-js";
import { v4 as uuidv4 } from "uuid";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const BecomeDistributor = () => {
  const language = useSelector((state) => state.language);
  const url = `https://www.focusactuators.com/become_a_distributor/${language}`;

  const { language: x } = useParams();
  useEffect(() => {
    if (x && !["en", "fr", "ru", "tr"].includes(x)) {
      window.location.pathname = `/404`;
    } else if (x && window.location.pathname.endsWith("/")) {
      const newPathname = window.location.pathname.slice(0, -1);
      window.location.pathname = newPathname;
    }
  }, [x]);

  const getPDFLink = (language) => {
    switch (language) {
      case "en":
        return "/pdf_en/focus_brochure.pdf";
      case "fr":
        return "/pdf_fr/focus_brochure.pdf";
      case "ru":
        return "/pdf_ru/focus_brochure.pdf";
      case "tr":
        return "/pdf_tr/focus_brochure.pdf";
      default:
        return "/pdf_en/focus_brochure.pdf";
    }
  };

  const pdfLink = getPDFLink(language);

  const languageData = useSelector((state) => state.data);
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const [isSuccessArray, setIsSuccessArray] = useState([false, false, false]);

  const [userInfo, setUserInfo] = useState("");
  const [tel, setTel] = useState("9999999999");
  const [subject, setSubject] = useState("Become a Distributor Form");
  const [from, setFrom] = useState("");
  const [message, setMessage] = useState("");
  const [files, setFiles] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState({
    city: "",
    country: "",
    size: "",
    website: "",
    brand: "",
    hear: "",
    want: "",
    turnover: "choose",
    sectors: [],
    warehouseOwnership: "",
    actuatorBrand: "",
    stillBrand: "",
    beforeFocus: "",
  });

  const handleNameInputChange = (e) => {
    setUserInfo(e.target.value);
  };
  const handleEmailInputChange = (e) => {
    setFrom(e.target.value);
  };
  const handleSubjectInputChange = (e) => {
    setSubject(e.target.value);
  };
  const handlePhoneInputChange = (e) => {
    setTel(e.target.value);
  };
  const handleMessageInputChange = (e) => {
    setMessage(e.target.value);
    // Ek bilgileri burada topla
    const additionalInfoArray = e.target.value.split("\n");
    setAdditionalInfo({
      city: additionalInfoArray[0],
      size: additionalInfoArray[1],
      brand: additionalInfoArray[2],
      hear: additionalInfoArray[3],
      want: additionalInfoArray[4],
      turnover: additionalInfo.turnover,
      sectors: additionalInfo.sectors,
      warehouseOwnership: additionalInfo.warehouseOwnership,
      actuatorBrand: additionalInfo.actuatorBrand,
      stillBrand: additionalInfo.stillBrand,
      beforeFocus: additionalInfo.beforeFocus,
    });
  };
  const handleTurnoverChange = (e) => {
    setAdditionalInfo({
      ...additionalInfo,
      turnover: e.target.value,
    });
  };
  const handleSectorChange = (e) => {
    const selectedSector = e.target.value;
    const updatedSectors = [...additionalInfo.sectors];

    if (e.target.checked) {
      // Checkbox işaretliyse sektörü ekleyin
      updatedSectors.push(selectedSector);
    } else {
      // Checkbox işaretsizse sektörü kaldırın
      const index = updatedSectors.indexOf(selectedSector);
      if (index !== -1) {
        updatedSectors.splice(index, 1);
      }
    }

    setAdditionalInfo({
      ...additionalInfo,
      sectors: updatedSectors,
    });
  };
  const handleWarehouseOwnershipChange = (e) => {
    setAdditionalInfo({
      ...additionalInfo,
      warehouseOwnership: e.target.value,
    });
  };
  const handleActuatorBrandChange = (e) => {
    setAdditionalInfo({
      ...additionalInfo,
      actuatorBrand: e.target.value,
    });
  };
  const handleStillBrandChange = (e) => {
    setAdditionalInfo({
      ...additionalInfo,
      stillBrand: e.target.value,
    });
  };
  const handleBeforeFocusChange = (e) => {
    setAdditionalInfo({
      ...additionalInfo,
      beforeFocus: e.target.value,
    });
  };
  const handleFileInputChange = (e) => {
    const selectedFile = e.target.files[0];
    setFiles(selectedFile);
  };

  const form = useRef();
  const [isSuccess, setIsSuccess] = useState(false);
  const handleClickButton = async (e, tabIndex) => {
    e.preventDefault();

    const encryptedText = CryptoJS.AES.encrypt(
      uuidv4(),
      process.env.REACT_APP_SSK
    ).toString();

    const FD = new FormData();
    FD.append("accessToken", encryptedText);
    FD.append("ownerName", "kutluhan@focusactuators.com");
    FD.append("to", "kutluhan@focusactuators.com");
    FD.append("host", "smtp.gmail.com");
    FD.append("service", "gmail");
    FD.append(
      "message",
      `<p>Cover letter:${
        document.getElementById("message").value
      } </p><p>Company Location(city):${
        document.getElementById("city").value
      }</p><p>Company Location(country):${
        document.getElementById("country").value
      }</p><p>Company Website:${
        document.getElementById("website").value
      }</p><p>annual turnover (in USD):${
        document.getElementById("turnover").value
      }</p>
      <p>What is the size of the warehouse (in m²)?${
        document.getElementById("size").value
      }</p>
      <p>Where did you first hear about Focus?${
        document.getElementById("hear").value
      }</p> <p>Any additional information you want to share, please write it here.${
        document.getElementById("want").value
      }</p>`
    );
    FD.append("tel", tel);
    FD.append("subject", subject);
    FD.append("from", from);
    FD.append("userInfo", userInfo);
    FD.append("file", files);

    for (var pair of FD.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }
    try {
      const res = await axios.post("https://nodemailer.enesatalay.com/", FD, {
        auth: {
          username: "OSICrew_Node_Mailler",
          password: "1786oSi?173",
        },
      });
      if (res) {
        const updatedSuccessArray = [...isSuccessArray];
        updatedSuccessArray[tabIndex] = true;
        setIsSuccessArray(updatedSuccessArray);
        toast.success("Mesajınız gönderildi.", { autoClose: 4500 });
        console.log(res);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.message, { autoClose: 4500 });
    }
  };

  return (
    <>
      <Helmet>
        <title>{languageData.become_a_distributor}</title>
        <meta
          name="description"
          content="You can apply to become an international distributor of Focus. Simply fill & submit the below form and our sales team will contact you in no time."
        />
        <link rel="canonical" href={url} />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <ToastContainer />
      <Loader />
      <Fade top delay={800}>
        <div className="downing-page-div">
          <Header showMenu={showMenu} toggleMenu={toggleMenu} />
          <div className="container text-header d-flex justify-content-center flex-column align-items-center contact-page">
            {!showMenu && (
              <>
                <div className="col-10 col-lg-9 title-sticky">
                  <h1 className="title-main">
                    {languageData.become_a_distributor}
                  </h1>
                </div>
                <div className="bg-main col-12 d-flex align-items-center justify-content-center flex-column">
                  <div className="col-lg-10 col-12 mb-5 d-flex ">
                    <div className="light-bg-gray text-white w-100 p-3">
                      <div className="bg-gray m-0 p-2">
                        <h6 className="text-white">
                          {" "}
                          {languageData.bd_text_1}
                        </h6>
                        <p className="text-white">{languageData.bd_text_2}</p>
                      </div>

                      <div className=" bg-gray mt-4 p-2">
                        <form
                          id="distributor_form"
                          className="distributorForm"
                          ref={form}
                          onSubmit={handleClickButton}
                          encType="multipart/form-data"
                        >
                          <div className="">
                            <div className="col-12 mb-2">
                              <div className="form-group">
                                <input
                                  type="text"
                                  name="userInfo"
                                  className="form-control"
                                  placeholder={languageData.company_full_name}
                                  required
                                  data-error="Please enter your Company"
                                  onChange={handleNameInputChange}
                                />

                                <div className="help-block with-errors" />
                              </div>
                            </div>

                            <div className="col-12 mb-2">
                              <div className="form-group">
                                <input
                                  type="text"
                                  id="country"
                                  name="country"
                                  className="form-control"
                                  onChange={handleMessageInputChange}
                                  placeholder={languageData.company_location_1}
                                  required
                                  data-error="Please enter your country"
                                />
                                <input
                                  type="text"
                                  name="tel"
                                  value={tel}
                                  className="form-control d-none"
                                  onChange={handlePhoneInputChange}
                                  data-error="Please enter your tel"
                                />

                                <input
                                  type="text"
                                  name="subject"
                                  value={subject}
                                  className="form-control d-none"
                                  onChange={handleSubjectInputChange}
                                  data-error="Please enter your country"
                                />
                                <div className="help-block with-errors" />
                              </div>
                            </div>
                            <div className="col-12 mb-2">
                              <div className="form-group">
                                <input
                                  id="city"
                                  type="text"
                                  name="city"
                                  onChange={handleMessageInputChange}
                                  className="form-control"
                                  placeholder={languageData.company_location_2}
                                  required
                                  data-error="Please enter your city or province"
                                />

                                <div className="help-block with-errors" />
                              </div>
                            </div>
                            <div className="col-12 mb-2">
                              <div className="form-group">
                                <input
                                  id="website"
                                  type="text"
                                  name="website"
                                  className="form-control"
                                  placeholder={languageData.company_website}
                                  data-error="Please enter your company website"
                                />

                                <div className="help-block with-errors" />
                              </div>
                            </div>
                            <div className="col-12 mb-2">
                              <div className="form-group">
                                <label> {languageData.bd_text_3}</label>
                                <input
                                  type="file"
                                  name="file"
                                  files={files}
                                  onChange={handleFileInputChange}
                                />
                              </div>
                            </div>
                            {
                              <div className="col-12 mb-2">
                                <div className="form-group d-flex flex-column">
                                  <label htmlFor="turnover">
                                    {languageData.annual_turnover_ind_USD}
                                  </label>

                                  <select
                                    id="turnover"
                                    onChange={handleTurnoverChange}
                                    value={additionalInfo.turnover}
                                  >
                                    <option value="choose">
                                      {" "}
                                      {languageData.choose}
                                    </option>
                                    <option value="0-500.000">0-500.000</option>
                                    <option value="500,000-1,000,000">
                                      500,000-1,000,000
                                    </option>
                                    <option value="1,000,000-5,000,000">
                                      1,000,000-5,000,000
                                    </option>
                                    <option value="5,000,000+">
                                      5,000,000+
                                    </option>
                                  </select>

                                  <div className="help-block with-errors" />
                                </div>
                              </div>
                            }
                            <div className="col-12 mb-2">
                              <div className="form-group">
                                <input
                                  style={{
                                    color: "#fff",
                                    "::placeholder": { color: "#fff" },
                                  }}
                                  type="email"
                                  name="from"
                                  className="form-control"
                                  placeholder={
                                    languageData.representatives_email_address
                                  }
                                  required
                                  onChange={handleEmailInputChange}
                                  data-error="Please enter your e-mail address"
                                />

                                <div className="help-block with-errors" />
                              </div>
                            </div>

                            <div className="col-12 mb-2">
                              <div className="form-group">
                                <label>{languageData.bd_question_1}</label>

                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="irrigation"
                                    name="sector"
                                    value="Irrigation"
                                    onChange={handleSectorChange}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="irrigation"
                                  >
                                    {languageData.irrigation}
                                  </label>
                                </div>

                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="waterTreatment"
                                    name="sector"
                                    value="Water treatment plants"
                                    onChange={handleSectorChange}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="waterTreatment"
                                  >
                                    {languageData.water_treatment_plants}
                                  </label>
                                </div>

                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="damsHydropower"
                                    name="sector"
                                    onChange={handleSectorChange}
                                    value="Dams & hydropower"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="damsHydropower"
                                  >
                                    {languageData.dams_hydropower}
                                  </label>
                                </div>

                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="industry"
                                    name="sector"
                                    onChange={handleSectorChange}
                                    value="Industry"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="industry"
                                  >
                                    {languageData.Industry}
                                  </label>
                                </div>

                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="powerPlants"
                                    onChange={handleSectorChange}
                                    name="sector"
                                    value="Power plants"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="powerPlants"
                                  >
                                    {languageData.power_plants}
                                  </label>
                                </div>

                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="wasteWater"
                                    onChange={handleSectorChange}
                                    name="sector"
                                    value="Waste water"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="wasteWater"
                                  >
                                    {languageData.waste_water}
                                  </label>
                                </div>

                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="waterDistribution"
                                    onChange={handleSectorChange}
                                    name="sector"
                                    value="Water distribution"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="waterDistribution"
                                  >
                                    {languageData.water_distribution}
                                  </label>
                                </div>

                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="energy"
                                    onChange={handleSectorChange}
                                    name="sector"
                                    value="Energy"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="energy"
                                  >
                                    {languageData.energy}
                                  </label>
                                </div>

                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="chemical"
                                    onChange={handleSectorChange}
                                    name="sector"
                                    value="Chemical"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="chemical"
                                  >
                                    {languageData.checemical}
                                  </label>
                                </div>

                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="oilGas"
                                    name="sector"
                                    onChange={handleSectorChange}
                                    value="Oil & gas"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="oilGas"
                                  >
                                    {languageData.oil_gas}
                                  </label>
                                </div>

                                <div className="help-block with-errors" />
                              </div>
                            </div>

                            <div className="col-12 mb-2">
                              <div className="form-group d-flex flex-column">
                                <fieldset id="sectors">
                                  <legend> {languageData.bd_question_2}</legend>

                                  <div>
                                    <input
                                      type="radio"
                                      id="yes_1"
                                      name="focusActuator_1"
                                      value="yes"
                                      onChange={handleWarehouseOwnershipChange}
                                    />
                                    <label className="ms-2" for="yes">
                                      {languageData.yes}
                                    </label>
                                  </div>

                                  <div>
                                    <input
                                      type="radio"
                                      id="no_1"
                                      name="focusActuator_1"
                                      value="no"
                                      onChange={handleWarehouseOwnershipChange}
                                    />
                                    <label className="ms-2" for="no">
                                      {languageData.no}
                                    </label>
                                  </div>
                                </fieldset>
                              </div>
                            </div>

                            <div className="col-12 mb-2">
                              <div className="form-group">
                                <input
                                  type="number"
                                  name="size"
                                  id="size"
                                  onChange={handleMessageInputChange}
                                  className="form-control"
                                  placeholder={languageData.bd_question_3}
                                  data-error="Please enter a valid warehouse size"
                                  required
                                />
                                <div className="help-block with-errors" />
                              </div>
                            </div>

                            <div className="col-12 mb-2">
                              <div className="form-group d-flex flex-column">
                                <label>{languageData.bd_question_4} </label>
                                <div>
                                  <input
                                    type="radio"
                                    id="yes_2"
                                    onChange={handleActuatorBrandChange}
                                    name="focusActuator_2"
                                    value="yes"
                                  />
                                  <label className="ms-2" for="yes_2">
                                    {languageData.yes}
                                  </label>
                                </div>

                                <div>
                                  <input
                                    type="radio"
                                    id="no_2"
                                    name="focusActuator_2"
                                    onChange={handleActuatorBrandChange}
                                    value="no"
                                  />
                                  <label className="ms-2" for="no_2">
                                    {languageData.no}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 mb-2">
                              <div className="form-group d-flex flex-column">
                                <label>{languageData.bd_question_5} </label>
                                <div>
                                  <input
                                    type="radio"
                                    id="yes_3"
                                    name="focusActuator_3"
                                    onChange={handleStillBrandChange}
                                    value="yes"
                                  />
                                  <label className="ms-2" for="yes_3">
                                    {languageData.yes}
                                  </label>
                                </div>

                                <div>
                                  <input
                                    type="radio"
                                    id="no_3"
                                    name="focusActuator_3"
                                    onChange={handleStillBrandChange}
                                    value="no"
                                  />
                                  <label className="ms-2" for="no_3">
                                    {languageData.no}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 mb-2">
                              <div className="form-group">
                                <input
                                  id="brand"
                                  type="text"
                                  name="brand"
                                  onChange={handleMessageInputChange}
                                  className="form-control"
                                  placeholder={languageData.bd_question_6}
                                  required
                                  data-error="Please enter your brand"
                                />

                                <div className="help-block with-errors" />
                              </div>
                            </div>
                            <div className="col-12 mb-2">
                              <div className="form-group d-flex flex-column">
                                <label>{languageData.bd_question_7}</label>
                                <div>
                                  <input
                                    type="radio"
                                    id="yes_4"
                                    name="focusActuator"
                                    onChange={handleBeforeFocusChange}
                                    value="yes"
                                  />
                                  <label className="ms-2" for="yes_4">
                                    {languageData.yes}
                                  </label>
                                </div>

                                <div>
                                  <input
                                    type="radio"
                                    id="no_5"
                                    name="focusActuator"
                                    onChange={handleBeforeFocusChange}
                                    value="no"
                                  />
                                  <label className="ms-2" for="no_5">
                                    {languageData.no}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 mb-2">
                              <div className="form-group">
                                <input
                                  type="text"
                                  id="hear"
                                  name="hear"
                                  className="form-control"
                                  placeholder={languageData.bd_question_8}
                                  onChange={handleMessageInputChange}
                                />

                                <div className="help-block with-errors" />
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="form-group">
                                <textarea
                                  type="text"
                                  name="want"
                                  id="want"
                                  onChange={handleMessageInputChange}
                                  className="form-control"
                                  rows={4}
                                  placeholder={languageData.bd_text_4}
                                />
                                <div className="help-block with-errors" />
                              </div>
                            </div>

                            <textarea
                              type="text"
                              name="message"
                              id="message"
                              className="form-control mt-2"
                              rows={4}
                              onChange={handleMessageInputChange}
                              placeholder={languageData.cover_letter}
                              required
                              data-error="Please enter your Message"
                            />

                            <div className="col-12 d-flex justify-content-center">
                              <p
                                className="button-focus mt-5"
                                style={{ cursor: "pointer" }}
                                onSubmit={handleClickButton}
                                disabled={isSuccess}
                              >
                                {languageData.send}
                              </p>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="black-background-contact mt-5">
                    <div className="container d-flex justify-content-center align-items-center flex-column">
                      <div className="col-5 col-lg-9 col-sm-10  mt-5 d-lg-flex  d-md-flex justify-content-center text-white ">
                        <div className="col-12 col-lg-5 col-md-5   d-flex justify-content-center align-items-center flex-column p-3 bg-gray mb-2">
                          <BsFillTelephoneFill />
                          <p className="text-white">+90 850 309 7907</p>
                        </div>

                        <div className="col-12 col-lg-5 offset-lg-1 col-md-5 offset-md-1 d-flex justify-content-center align-items-center flex-column bg-gray mb-2">
                          <SlEnvolopeLetter />
                          <p className="text-white">sales@denz.co </p>
                        </div>
                      </div>

                      <div className="col-lg-9 col-md-10 col-5 col-sm-10 mt-5 d-flex text-white bg-gray justify-content-center flex-column">
                        <div className="d-lg-flex text-white ">
                          <div className="col-lg-6 col-12 p-3">
                            <div className="text-lg-center">
                              <ImLocation2 />
                            </div>

                            <h6>
                              <b>{languageData.headquarter}</b>
                            </h6>
                            <p>
                              Mutlukent Mahallesi 2016/1 Sokak No: 32 Çankaya
                              06800 Ankara Türkiye
                            </p>
                            <div className="text-lg-center">
                              <MdFactory />
                            </div>

                            <h6>
                              <b>{languageData.factory}</b>
                            </h6>
                            <p>
                              Ivedik OSB 1354. Cadde 1357. Sokak No:38
                              Yenimahalle/Ankara/Türkiye
                            </p>
                          </div>

                          <div className="col-lg-6 col-12">
                            <iframe
                              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d720.825027777664!2d32.71436530106184!3d39.88539397296358!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d3388309a352c1%3A0x96afe6b90c595552!2sMutlukent%2C%202016%2F1.%20Sk.%20No%3A32%2C%2006800%20%C3%87ankaya%2FAnkara!5e1!3m2!1str!2str!4v1739256899030!5m2!1str!2str"
                              style={{ border: 0 }}
                              allowFullScreen
                              loading="lazy"
                              referrerPolicy="no-referrer-when-downgrade"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-9 col-md-10 col-5 col-sm-10 mb-5 mt-5">
                        <div className="col-12 d-flex">
                          <div className="bg-gray text-white w-100">
                            <div className="light-bg-gray d-flex align-items-center p-2 w-100">
                              <div className="col-6 d-flex">
                                <h3 className="p-0 m-0">
                                  {languageData.focus_brochure}
                                </h3>
                              </div>
                              <div className="col-6 d-flex justify-content-end">
                                <a
                                  target="_blank"
                                  href={pdfLink}
                                  aria-label="become-disc"
                                >
                                  <p className="button-focus m-0">
                                    {languageData.download}
                                  </p>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="white-background-contact "></div>
                </div>
              </>
            )}
          </div>
          {!showMenu && <Footer />}
        </div>
      </Fade>
    </>
  );
};

export default BecomeDistributor;
